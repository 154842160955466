body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  overflow: hidden; /* Hide any content that overflows the viewport */
}

.tile-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, calc(20% - 1px)); /* Adjust the percentage as needed */
  grid-gap: 0;
  width: 100vw;
  height: 100vh;
}

.tile {
  width: 100%;
  padding-top: 100%; /* Maintain a 1:1 aspect ratio to create square tiles */
  background-size: cover; /* Fill the tile with the background color */
  transition: background-color 5s ease; /* Fade in transition for 5 seconds */
}
